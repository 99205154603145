import { render, staticRenderFns } from "./pageOnly.vue?vue&type=template&id=da93e55a"
import script from "./pageOnly.vue?vue&type=script&lang=js"
export * from "./pageOnly.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SnackBar: require('/app/components/SnackBar.vue').default,ModalSelectRegion: require('/app/components/modals/ModalSelectRegion.vue').default,ModalUtmAlert: require('/app/components/modals/ModalUtmAlert.vue').default})
