
import { mapGetters, mapMutations } from 'vuex';

import { PartnersSource } from '@/models/PurchaseSource';
import { dynamicYieldCampaign } from '@/services/dynamicYield';
import { dinamicYieldApiSelectors } from '@/services/dynamicYield/campaigns';
import { loadRdStationScript } from '@/utils/rdstationScript';
import Track from '@/utils/track';

export default {
  data() {
    return {
      selectRegion: false
    };
  },

  computed: {
    ...mapGetters({
      regions: 'getRegions',
      userData: 'customerData',
      partnerWhitelabelConfig: 'getPartnerWhitelabelConfig',
      getVariantDownloadApp: 'getVariantDownloadApp'
    })
  },

  created() {
    this.$nuxt.$off('show:modal');
    this.$nuxt.$on('show:modal', (modal) => {
      this[modal] = true;
    });

    this.$nuxt.$off('hide:modal');
    this.$nuxt.$on('hide:modal', (modal) => {
      this[modal] = false;
    });
  },

  beforeDestroy() {
    this.$nuxt.$off('show:modal');
    this.$nuxt.$off('hide:modal');
  },

  async mounted() {
    setTimeout(() => {
      window?.DYO?.smartObject('health-banner', {
        target: 'dy_health-banner',
        inline: true
      });
    }, 600);

    const cookieUtmSource = sessionStorage.getItem('utm_source');
    const routeUtmSource = this.$route?.query?.utm_source;

    const utmSourceValue = cookieUtmSource || routeUtmSource || '';

    if (utmSourceValue) {
      await this.$store.dispatch('setPartnerConfig', utmSourceValue);
    }

    const shouldLoadRdStation = !PartnersSource.HIDE_WHATSAPP_BUTTON_LIST.some(
      (source) => utmSourceValue?.includes(source)
    );

    if (
      shouldLoadRdStation &&
      !this.partnerWhitelabelConfig.hideWhatsappButton
    ) {
      loadRdStationScript();
    }

    Track.watchNotifyEvent(this.$root, this.userData);
    if (window?.clarity) window.clarity('identify', this.userData.email);
    this.setDownloadAppVariantDY();
  },
  methods: {
    ...mapMutations(['set_variantDownloadApp']),
    async setDownloadAppVariantDY() {
      const campaignName = dinamicYieldApiSelectors.downloadApp;
      await dynamicYieldCampaign(
        this.getVariantDownloadApp,
        campaignName,
        'set_variantDownloadApp'
      );
    }
  }
};
