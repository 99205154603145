export const CaActionChip = () => import('../../node_modules/@petlove/caramelo-vue-components/src/ActionChip/index.vue' /* webpackChunkName: "components/ca-action-chip" */).then(c => wrapFunctional(c.default || c))
export const CaActionChipGroup = () => import('../../node_modules/@petlove/caramelo-vue-components/src/ActionChipGroup/index.vue' /* webpackChunkName: "components/ca-action-chip-group" */).then(c => wrapFunctional(c.default || c))
export const CaAlert = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Alert/index.vue' /* webpackChunkName: "components/ca-alert" */).then(c => wrapFunctional(c.default || c))
export const CaAvatar = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Avatar/index.vue' /* webpackChunkName: "components/ca-avatar" */).then(c => wrapFunctional(c.default || c))
export const CaAvatarGroup = () => import('../../node_modules/@petlove/caramelo-vue-components/src/AvatarGroup/index.vue' /* webpackChunkName: "components/ca-avatar-group" */).then(c => wrapFunctional(c.default || c))
export const CaBadge = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Badge/index.vue' /* webpackChunkName: "components/ca-badge" */).then(c => wrapFunctional(c.default || c))
export const CaBottomNav = () => import('../../node_modules/@petlove/caramelo-vue-components/src/BottomNav/index.vue' /* webpackChunkName: "components/ca-bottom-nav" */).then(c => wrapFunctional(c.default || c))
export const CaButton = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Button/index.vue' /* webpackChunkName: "components/ca-button" */).then(c => wrapFunctional(c.default || c))
export const CaCard = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Card/index.vue' /* webpackChunkName: "components/ca-card" */).then(c => wrapFunctional(c.default || c))
export const CaCardAccordion = () => import('../../node_modules/@petlove/caramelo-vue-components/src/CardAccordion/index.vue' /* webpackChunkName: "components/ca-card-accordion" */).then(c => wrapFunctional(c.default || c))
export const CaCardImage = () => import('../../node_modules/@petlove/caramelo-vue-components/src/CardImage/index.vue' /* webpackChunkName: "components/ca-card-image" */).then(c => wrapFunctional(c.default || c))
export const CaCardSelector = () => import('../../node_modules/@petlove/caramelo-vue-components/src/CardSelector/index.vue' /* webpackChunkName: "components/ca-card-selector" */).then(c => wrapFunctional(c.default || c))
export const CaChat = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Chat/index.vue' /* webpackChunkName: "components/ca-chat" */).then(c => wrapFunctional(c.default || c))
export const CaCheckbox = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Checkbox/index.vue' /* webpackChunkName: "components/ca-checkbox" */).then(c => wrapFunctional(c.default || c))
export const CaChip = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Chip/index.vue' /* webpackChunkName: "components/ca-chip" */).then(c => wrapFunctional(c.default || c))
export const CaChipGroup = () => import('../../node_modules/@petlove/caramelo-vue-components/src/ChipGroup/index.vue' /* webpackChunkName: "components/ca-chip-group" */).then(c => wrapFunctional(c.default || c))
export const CaCircularProgress = () => import('../../node_modules/@petlove/caramelo-vue-components/src/CircularProgress/index.vue' /* webpackChunkName: "components/ca-circular-progress" */).then(c => wrapFunctional(c.default || c))
export const CaCircularProgressBar = () => import('../../node_modules/@petlove/caramelo-vue-components/src/CircularProgressBar/index.vue' /* webpackChunkName: "components/ca-circular-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const CaCounter = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Counter/index.vue' /* webpackChunkName: "components/ca-counter" */).then(c => wrapFunctional(c.default || c))
export const CaCustomSwitch = () => import('../../node_modules/@petlove/caramelo-vue-components/src/CustomSwitch/index.vue' /* webpackChunkName: "components/ca-custom-switch" */).then(c => wrapFunctional(c.default || c))
export const CaDialog = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Dialog/index.vue' /* webpackChunkName: "components/ca-dialog" */).then(c => wrapFunctional(c.default || c))
export const CaDivisor = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Divisor/index.vue' /* webpackChunkName: "components/ca-divisor" */).then(c => wrapFunctional(c.default || c))
export const CaDrawer = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Drawer/index.vue' /* webpackChunkName: "components/ca-drawer" */).then(c => wrapFunctional(c.default || c))
export const CaEmptyState = () => import('../../node_modules/@petlove/caramelo-vue-components/src/EmptyState/index.vue' /* webpackChunkName: "components/ca-empty-state" */).then(c => wrapFunctional(c.default || c))
export const CaFaq = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Faq/index.vue' /* webpackChunkName: "components/ca-faq" */).then(c => wrapFunctional(c.default || c))
export const CaFooterDesktop = () => import('../../node_modules/@petlove/caramelo-vue-components/src/FooterDesktop/index.vue' /* webpackChunkName: "components/ca-footer-desktop" */).then(c => wrapFunctional(c.default || c))
export const CaFooterMobile = () => import('../../node_modules/@petlove/caramelo-vue-components/src/FooterMobile/index.vue' /* webpackChunkName: "components/ca-footer-mobile" */).then(c => wrapFunctional(c.default || c))
export const CaHeader = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Header/index.vue' /* webpackChunkName: "components/ca-header" */).then(c => wrapFunctional(c.default || c))
export const CaIcon = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Icon/index.vue' /* webpackChunkName: "components/ca-icon" */).then(c => wrapFunctional(c.default || c))
export const CaIconButton = () => import('../../node_modules/@petlove/caramelo-vue-components/src/IconButton/index.vue' /* webpackChunkName: "components/ca-icon-button" */).then(c => wrapFunctional(c.default || c))
export const CaImageCarousel = () => import('../../node_modules/@petlove/caramelo-vue-components/src/ImageCarousel/index.vue' /* webpackChunkName: "components/ca-image-carousel" */).then(c => wrapFunctional(c.default || c))
export const CaInputPassword = () => import('../../node_modules/@petlove/caramelo-vue-components/src/InputPassword/index.vue' /* webpackChunkName: "components/ca-input-password" */).then(c => wrapFunctional(c.default || c))
export const CaInputSearch = () => import('../../node_modules/@petlove/caramelo-vue-components/src/InputSearch/index.vue' /* webpackChunkName: "components/ca-input-search" */).then(c => wrapFunctional(c.default || c))
export const CaInputSearchAutocomplete = () => import('../../node_modules/@petlove/caramelo-vue-components/src/InputSearchAutocomplete/index.vue' /* webpackChunkName: "components/ca-input-search-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const CaInputSearchTag = () => import('../../node_modules/@petlove/caramelo-vue-components/src/InputSearchTag/index.vue' /* webpackChunkName: "components/ca-input-search-tag" */).then(c => wrapFunctional(c.default || c))
export const CaInputText = () => import('../../node_modules/@petlove/caramelo-vue-components/src/InputText/index.vue' /* webpackChunkName: "components/ca-input-text" */).then(c => wrapFunctional(c.default || c))
export const CaLazyImage = () => import('../../node_modules/@petlove/caramelo-vue-components/src/LazyImage/index.vue' /* webpackChunkName: "components/ca-lazy-image" */).then(c => wrapFunctional(c.default || c))
export const CaLinearProgressBar = () => import('../../node_modules/@petlove/caramelo-vue-components/src/LinearProgressBar/index.vue' /* webpackChunkName: "components/ca-linear-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const CaLink = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Link/index.vue' /* webpackChunkName: "components/ca-link" */).then(c => wrapFunctional(c.default || c))
export const CaLoading = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Loading/index.vue' /* webpackChunkName: "components/ca-loading" */).then(c => wrapFunctional(c.default || c))
export const CaModal = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Modal/index.vue' /* webpackChunkName: "components/ca-modal" */).then(c => wrapFunctional(c.default || c))
export const CaProductCard = () => import('../../node_modules/@petlove/caramelo-vue-components/src/ProductCard/index.vue' /* webpackChunkName: "components/ca-product-card" */).then(c => wrapFunctional(c.default || c))
export const CaProgressBar = () => import('../../node_modules/@petlove/caramelo-vue-components/src/ProgressBar/index.vue' /* webpackChunkName: "components/ca-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const CaQuickFilters = () => import('../../node_modules/@petlove/caramelo-vue-components/src/QuickFilters/index.vue' /* webpackChunkName: "components/ca-quick-filters" */).then(c => wrapFunctional(c.default || c))
export const CaRadio = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Radio/index.vue' /* webpackChunkName: "components/ca-radio" */).then(c => wrapFunctional(c.default || c))
export const CaRating = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Rating/index.vue' /* webpackChunkName: "components/ca-rating" */).then(c => wrapFunctional(c.default || c))
export const CaRatingHighlightMini = () => import('../../node_modules/@petlove/caramelo-vue-components/src/RatingHighlightMini/index.vue' /* webpackChunkName: "components/ca-rating-highlight-mini" */).then(c => wrapFunctional(c.default || c))
export const CaRatingHightlight = () => import('../../node_modules/@petlove/caramelo-vue-components/src/RatingHightlight/index.vue' /* webpackChunkName: "components/ca-rating-hightlight" */).then(c => wrapFunctional(c.default || c))
export const CaScroll = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Scroll/index.vue' /* webpackChunkName: "components/ca-scroll" */).then(c => wrapFunctional(c.default || c))
export const CaSelect = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Select/index.vue' /* webpackChunkName: "components/ca-select" */).then(c => wrapFunctional(c.default || c))
export const CaSlider = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Slider/index.vue' /* webpackChunkName: "components/ca-slider" */).then(c => wrapFunctional(c.default || c))
export const CaSnackbar = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Snackbar/index.vue' /* webpackChunkName: "components/ca-snackbar" */).then(c => wrapFunctional(c.default || c))
export const CaSteps = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Steps/index.vue' /* webpackChunkName: "components/ca-steps" */).then(c => wrapFunctional(c.default || c))
export const CaSupportContent = () => import('../../node_modules/@petlove/caramelo-vue-components/src/SupportContent/index.vue' /* webpackChunkName: "components/ca-support-content" */).then(c => wrapFunctional(c.default || c))
export const CaTabs = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Tabs/index.vue' /* webpackChunkName: "components/ca-tabs" */).then(c => wrapFunctional(c.default || c))
export const CaTag = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Tag/index.vue' /* webpackChunkName: "components/ca-tag" */).then(c => wrapFunctional(c.default || c))
export const CaTextarea = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Textarea/index.vue' /* webpackChunkName: "components/ca-textarea" */).then(c => wrapFunctional(c.default || c))
export const CaThumbnail = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Thumbnail/index.vue' /* webpackChunkName: "components/ca-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const CaThumbnailGroup = () => import('../../node_modules/@petlove/caramelo-vue-components/src/ThumbnailGroup/index.vue' /* webpackChunkName: "components/ca-thumbnail-group" */).then(c => wrapFunctional(c.default || c))
export const CaTitle = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Title/index.vue' /* webpackChunkName: "components/ca-title" */).then(c => wrapFunctional(c.default || c))
export const CaTooltip = () => import('../../node_modules/@petlove/caramelo-vue-components/src/Tooltip/index.vue' /* webpackChunkName: "components/ca-tooltip" */).then(c => wrapFunctional(c.default || c))
export const AlertCookies = () => import('../../components/AlertCookies.vue' /* webpackChunkName: "components/alert-cookies" */).then(c => wrapFunctional(c.default || c))
export const Callback = () => import('../../components/Callback.vue' /* webpackChunkName: "components/callback" */).then(c => wrapFunctional(c.default || c))
export const CallbackButton = () => import('../../components/CallbackButton.vue' /* webpackChunkName: "components/callback-button" */).then(c => wrapFunctional(c.default || c))
export const CallbackModal = () => import('../../components/CallbackModal.vue' /* webpackChunkName: "components/callback-modal" */).then(c => wrapFunctional(c.default || c))
export const Faqs = () => import('../../components/Faqs.vue' /* webpackChunkName: "components/faqs" */).then(c => wrapFunctional(c.default || c))
export const FloatingPopup = () => import('../../components/FloatingPopup.vue' /* webpackChunkName: "components/floating-popup" */).then(c => wrapFunctional(c.default || c))
export const FormRegisterIndication = () => import('../../components/FormRegisterIndication.vue' /* webpackChunkName: "components/form-register-indication" */).then(c => wrapFunctional(c.default || c))
export const HeaderCountdown = () => import('../../components/HeaderCountdown.vue' /* webpackChunkName: "components/header-countdown" */).then(c => wrapFunctional(c.default || c))
export const HeaderSimple = () => import('../../components/HeaderSimple.vue' /* webpackChunkName: "components/header-simple" */).then(c => wrapFunctional(c.default || c))
export const InfoCard = () => import('../../components/InfoCard.vue' /* webpackChunkName: "components/info-card" */).then(c => wrapFunctional(c.default || c))
export const LocationAddressDrawer = () => import('../../components/LocationAddressDrawer.vue' /* webpackChunkName: "components/location-address-drawer" */).then(c => wrapFunctional(c.default || c))
export const LocationCard = () => import('../../components/LocationCard.vue' /* webpackChunkName: "components/location-card" */).then(c => wrapFunctional(c.default || c))
export const LocationSelect = () => import('../../components/LocationSelect.vue' /* webpackChunkName: "components/location-select" */).then(c => wrapFunctional(c.default || c))
export const MapsStaticAPI = () => import('../../components/MapsStaticAPI.vue' /* webpackChunkName: "components/maps-static-a-p-i" */).then(c => wrapFunctional(c.default || c))
export const MobileMenu = () => import('../../components/MobileMenu.vue' /* webpackChunkName: "components/mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const ModalBulma = () => import('../../components/ModalBulma.vue' /* webpackChunkName: "components/modal-bulma" */).then(c => wrapFunctional(c.default || c))
export const NofaroToast = () => import('../../components/NofaroToast.vue' /* webpackChunkName: "components/nofaro-toast" */).then(c => wrapFunctional(c.default || c))
export const NofaroTooltip = () => import('../../components/NofaroTooltip.vue' /* webpackChunkName: "components/nofaro-tooltip" */).then(c => wrapFunctional(c.default || c))
export const OfferBannerPetlove = () => import('../../components/OfferBannerPetlove.vue' /* webpackChunkName: "components/offer-banner-petlove" */).then(c => wrapFunctional(c.default || c))
export const SiteHowItWorks = () => import('../../components/SiteHowItWorks.vue' /* webpackChunkName: "components/site-how-it-works" */).then(c => wrapFunctional(c.default || c))
export const SiteTestimonials = () => import('../../components/SiteTestimonials.vue' /* webpackChunkName: "components/site-testimonials" */).then(c => wrapFunctional(c.default || c))
export const SnackBar = () => import('../../components/SnackBar.vue' /* webpackChunkName: "components/snack-bar" */).then(c => wrapFunctional(c.default || c))
export const YouTubePlayer = () => import('../../components/YouTubePlayer.vue' /* webpackChunkName: "components/you-tube-player" */).then(c => wrapFunctional(c.default || c))
export const SacButton = () => import('../../components/SAC/SacButton.vue' /* webpackChunkName: "components/sac-button" */).then(c => wrapFunctional(c.default || c))
export const SacError = () => import('../../components/SAC/SacError.vue' /* webpackChunkName: "components/sac-error" */).then(c => wrapFunctional(c.default || c))
export const SacForm = () => import('../../components/SAC/SacForm.vue' /* webpackChunkName: "components/sac-form" */).then(c => wrapFunctional(c.default || c))
export const SacLoading = () => import('../../components/SAC/SacLoading.vue' /* webpackChunkName: "components/sac-loading" */).then(c => wrapFunctional(c.default || c))
export const SacOptions = () => import('../../components/SAC/SacOptions.vue' /* webpackChunkName: "components/sac-options" */).then(c => wrapFunctional(c.default || c))
export const SacSuccess = () => import('../../components/SAC/SacSuccess.vue' /* webpackChunkName: "components/sac-success" */).then(c => wrapFunctional(c.default || c))
export const SacWhats = () => import('../../components/SAC/SacWhats.vue' /* webpackChunkName: "components/sac-whats" */).then(c => wrapFunctional(c.default || c))
export const SacWhatsappButton = () => import('../../components/SAC/SacWhatsappButton.vue' /* webpackChunkName: "components/sac-whatsapp-button" */).then(c => wrapFunctional(c.default || c))
export const Error404 = () => import('../../components/errors/Error404.vue' /* webpackChunkName: "components/error404" */).then(c => wrapFunctional(c.default || c))
export const Error500 = () => import('../../components/errors/Error500.vue' /* webpackChunkName: "components/error500" */).then(c => wrapFunctional(c.default || c))
export const CompanyLegal = () => import('../../components/footer/CompanyLegal.vue' /* webpackChunkName: "components/company-legal" */).then(c => wrapFunctional(c.default || c))
export const FooterDesktop = () => import('../../components/footer/FooterDesktop.vue' /* webpackChunkName: "components/footer-desktop" */).then(c => wrapFunctional(c.default || c))
export const FooterIndication = () => import('../../components/footer/FooterIndication.vue' /* webpackChunkName: "components/footer-indication" */).then(c => wrapFunctional(c.default || c))
export const FooterPolicyLinks = () => import('../../components/footer/FooterPolicyLinks.vue' /* webpackChunkName: "components/footer-policy-links" */).then(c => wrapFunctional(c.default || c))
export const FooterSimple = () => import('../../components/footer/FooterSimple.vue' /* webpackChunkName: "components/footer-simple" */).then(c => wrapFunctional(c.default || c))
export const UnionLogos = () => import('../../components/footer/UnionLogos.vue' /* webpackChunkName: "components/union-logos" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/header/Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const RegionFilter = () => import('../../components/header/RegionFilter.vue' /* webpackChunkName: "components/region-filter" */).then(c => wrapFunctional(c.default || c))
export const SubMenu = () => import('../../components/header/SubMenu.vue' /* webpackChunkName: "components/sub-menu" */).then(c => wrapFunctional(c.default || c))
export const Home = () => import('../../components/home/index.vue' /* webpackChunkName: "components/home" */).then(c => wrapFunctional(c.default || c))
export const DrawerBeAffiliate = () => import('../../components/modals/DrawerBeAffiliate.vue' /* webpackChunkName: "components/drawer-be-affiliate" */).then(c => wrapFunctional(c.default || c))
export const DrawerRequestAccreditation = () => import('../../components/modals/DrawerRequestAccreditation.vue' /* webpackChunkName: "components/drawer-request-accreditation" */).then(c => wrapFunctional(c.default || c))
export const DrawerRequestAccreditationSuccess = () => import('../../components/modals/DrawerRequestAccreditationSuccess.vue' /* webpackChunkName: "components/drawer-request-accreditation-success" */).then(c => wrapFunctional(c.default || c))
export const DrawerRequestBenefits = () => import('../../components/modals/DrawerRequestBenefits.vue' /* webpackChunkName: "components/drawer-request-benefits" */).then(c => wrapFunctional(c.default || c))
export const DrawerSuccess = () => import('../../components/modals/DrawerSuccess.vue' /* webpackChunkName: "components/drawer-success" */).then(c => wrapFunctional(c.default || c))
export const ModalAdditional = () => import('../../components/modals/ModalAdditional.vue' /* webpackChunkName: "components/modal-additional" */).then(c => wrapFunctional(c.default || c))
export const ModalBenefitConfirmation = () => import('../../components/modals/ModalBenefitConfirmation.vue' /* webpackChunkName: "components/modal-benefit-confirmation" */).then(c => wrapFunctional(c.default || c))
export const ModalCheckoutDuplicatedPets = () => import('../../components/modals/ModalCheckoutDuplicatedPets.vue' /* webpackChunkName: "components/modal-checkout-duplicated-pets" */).then(c => wrapFunctional(c.default || c))
export const ModalSelectRegion = () => import('../../components/modals/ModalSelectRegion.vue' /* webpackChunkName: "components/modal-select-region" */).then(c => wrapFunctional(c.default || c))
export const ModalUtmAlert = () => import('../../components/modals/ModalUtmAlert.vue' /* webpackChunkName: "components/modal-utm-alert" */).then(c => wrapFunctional(c.default || c))
export const ModalVideoYouTube = () => import('../../components/modals/ModalVideoYouTube.vue' /* webpackChunkName: "components/modal-video-you-tube" */).then(c => wrapFunctional(c.default || c))
export const Modals = () => import('../../components/modals/Modals.vue' /* webpackChunkName: "components/modals" */).then(c => wrapFunctional(c.default || c))
export const InputSelectAddress = () => import('../../components/selectAddress/InputSelectAddress.vue' /* webpackChunkName: "components/input-select-address" */).then(c => wrapFunctional(c.default || c))
export const MessageBox = () => import('../../components/selectAddress/MessageBox.vue' /* webpackChunkName: "components/message-box" */).then(c => wrapFunctional(c.default || c))
export const SelectAddress = () => import('../../components/selectAddress/SelectAddress.vue' /* webpackChunkName: "components/select-address" */).then(c => wrapFunctional(c.default || c))
export const SideModalContent = () => import('../../components/selectAddress/SideModalContent.vue' /* webpackChunkName: "components/side-modal-content" */).then(c => wrapFunctional(c.default || c))
export const CardsSelector = () => import('../../components/shared/CardsSelector.vue' /* webpackChunkName: "components/cards-selector" */).then(c => wrapFunctional(c.default || c))
export const CreditCardIcon = () => import('../../components/shared/CreditCardIcon.vue' /* webpackChunkName: "components/credit-card-icon" */).then(c => wrapFunctional(c.default || c))
export const RegionSelector = () => import('../../components/shared/RegionSelector.vue' /* webpackChunkName: "components/region-selector" */).then(c => wrapFunctional(c.default || c))
export const ButtonModalSimulador = () => import('../../components/simulador/ButtonModalSimulador.vue' /* webpackChunkName: "components/button-modal-simulador" */).then(c => wrapFunctional(c.default || c))
export const SimularValores = () => import('../../components/simulador/SimularValores.vue' /* webpackChunkName: "components/simular-valores" */).then(c => wrapFunctional(c.default || c))
export const CampaignBanner = () => import('../../components/header/HeaderBanners/CampaignBanner.vue' /* webpackChunkName: "components/campaign-banner" */).then(c => wrapFunctional(c.default || c))
export const NewCampaignBanner = () => import('../../components/header/HeaderBanners/NewCampaignBanner.vue' /* webpackChunkName: "components/new-campaign-banner" */).then(c => wrapFunctional(c.default || c))
export const NewPromotionalBanner = () => import('../../components/header/HeaderBanners/NewPromotionalBanner.vue' /* webpackChunkName: "components/new-promotional-banner" */).then(c => wrapFunctional(c.default || c))
export const PartnerBanner = () => import('../../components/header/HeaderBanners/PartnerBanner.vue' /* webpackChunkName: "components/partner-banner" */).then(c => wrapFunctional(c.default || c))
export const PromotionalBanners = () => import('../../components/header/HeaderBanners/PromotionalBanners.vue' /* webpackChunkName: "components/promotional-banners" */).then(c => wrapFunctional(c.default || c))
export const HeaderBanners = () => import('../../components/header/HeaderBanners/index.vue' /* webpackChunkName: "components/header-banners" */).then(c => wrapFunctional(c.default || c))
export const NavbarDesktop = () => import('../../components/header/NavbarDesktop/index.vue' /* webpackChunkName: "components/navbar-desktop" */).then(c => wrapFunctional(c.default || c))
export const MenuMobile = () => import('../../components/header/NavbarMobile/MenuMobile.vue' /* webpackChunkName: "components/menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const NavbarMobile = () => import('../../components/header/NavbarMobile/index.vue' /* webpackChunkName: "components/navbar-mobile" */).then(c => wrapFunctional(c.default || c))
export const AccreditedNetworkSection = () => import('../../components/home/AccreditedNetworkSection/index.vue' /* webpackChunkName: "components/accredited-network-section" */).then(c => wrapFunctional(c.default || c))
export const ErrorModalCallback = () => import('../../components/home/CallbackSection/ErrorModalCallback.vue' /* webpackChunkName: "components/error-modal-callback" */).then(c => wrapFunctional(c.default || c))
export const SuccessModalCallback = () => import('../../components/home/CallbackSection/SuccessModalCallback.vue' /* webpackChunkName: "components/success-modal-callback" */).then(c => wrapFunctional(c.default || c))
export const CallbackSection = () => import('../../components/home/CallbackSection/index.vue' /* webpackChunkName: "components/callback-section" */).then(c => wrapFunctional(c.default || c))
export const HealthBenefitsSection = () => import('../../components/home/HealthBenefitsSection/index.vue' /* webpackChunkName: "components/health-benefits-section" */).then(c => wrapFunctional(c.default || c))
export const HeroSection = () => import('../../components/home/HeroSection/index.vue' /* webpackChunkName: "components/hero-section" */).then(c => wrapFunctional(c.default || c))
export const HowItWorksSection = () => import('../../components/home/HowItWorksSection/index.vue' /* webpackChunkName: "components/how-it-works-section" */).then(c => wrapFunctional(c.default || c))
export const PetloveClubSection = () => import('../../components/home/PetloveClubSection/index.vue' /* webpackChunkName: "components/petlove-club-section" */).then(c => wrapFunctional(c.default || c))
export const PlansCard = () => import('../../components/home/PlansSection/PlansCard.vue' /* webpackChunkName: "components/plans-card" */).then(c => wrapFunctional(c.default || c))
export const PlansSection = () => import('../../components/home/PlansSection/index.vue' /* webpackChunkName: "components/plans-section" */).then(c => wrapFunctional(c.default || c))
export const ReviewsSection = () => import('../../components/home/ReviewsSection/index.vue' /* webpackChunkName: "components/reviews-section" */).then(c => wrapFunctional(c.default || c))
export const TransparencySection = () => import('../../components/home/TransparencySection/index.vue' /* webpackChunkName: "components/transparency-section" */).then(c => wrapFunctional(c.default || c))
export const BuyHeader = () => import('../../components/pages/Buy/BuyHeader.vue' /* webpackChunkName: "components/buy-header" */).then(c => wrapFunctional(c.default || c))
export const BuyPlansCarroussel = () => import('../../components/pages/Buy/BuyPlansCarroussel.vue' /* webpackChunkName: "components/buy-plans-carroussel" */).then(c => wrapFunctional(c.default || c))
export const EmbeddedBundleCarousel = () => import('../../components/pages/Buy/EmbeddedBundleCarousel.vue' /* webpackChunkName: "components/embedded-bundle-carousel" */).then(c => wrapFunctional(c.default || c))
export const FooterOrderSummary = () => import('../../components/pages/Buy/FooterOrderSummary.vue' /* webpackChunkName: "components/footer-order-summary" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/pages/Buy/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const ModalBuyTerms = () => import('../../components/pages/Buy/ModalBuyTerms.vue' /* webpackChunkName: "components/modal-buy-terms" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirmation = () => import('../../components/pages/Buy/ModalConfirmation.vue' /* webpackChunkName: "components/modal-confirmation" */).then(c => wrapFunctional(c.default || c))
export const RegionChangeAlertModal = () => import('../../components/pages/Buy/RegionChangeAlertModal.vue' /* webpackChunkName: "components/region-change-alert-modal" */).then(c => wrapFunctional(c.default || c))
export const Timer = () => import('../../components/pages/Buy/Timer.vue' /* webpackChunkName: "components/timer" */).then(c => wrapFunctional(c.default || c))
export const AffiliatesSectionBe = () => import('../../components/pages/afiliados/AffiliatesSectionBe.vue' /* webpackChunkName: "components/affiliates-section-be" */).then(c => wrapFunctional(c.default || c))
export const AffiliatesSectionFaq = () => import('../../components/pages/afiliados/AffiliatesSectionFaq.vue' /* webpackChunkName: "components/affiliates-section-faq" */).then(c => wrapFunctional(c.default || c))
export const AffiliatesSectionHowItWorks = () => import('../../components/pages/afiliados/AffiliatesSectionHowItWorks.vue' /* webpackChunkName: "components/affiliates-section-how-it-works" */).then(c => wrapFunctional(c.default || c))
export const AffiliatesSectionMarket = () => import('../../components/pages/afiliados/AffiliatesSectionMarket.vue' /* webpackChunkName: "components/affiliates-section-market" */).then(c => wrapFunctional(c.default || c))
export const AffiliatesSectionRegister = () => import('../../components/pages/afiliados/AffiliatesSectionRegister.vue' /* webpackChunkName: "components/affiliates-section-register" */).then(c => wrapFunctional(c.default || c))
export const AffiliatesSectionWhy = () => import('../../components/pages/afiliados/AffiliatesSectionWhy.vue' /* webpackChunkName: "components/affiliates-section-why" */).then(c => wrapFunctional(c.default || c))
export const CardComponent = () => import('../../components/pages/benefits/CardComponent.vue' /* webpackChunkName: "components/card-component" */).then(c => wrapFunctional(c.default || c))
export const MiniCard = () => import('../../components/pages/benefits/MiniCard.vue' /* webpackChunkName: "components/mini-card" */).then(c => wrapFunctional(c.default || c))
export const AccreditationSupportContent = () => import('../../components/pages/credenciamento/AccreditationSupportContent.vue' /* webpackChunkName: "components/accreditation-support-content" */).then(c => wrapFunctional(c.default || c))
export const BlogCardPost = () => import('../../components/pages/blog/BlogCardPost.vue' /* webpackChunkName: "components/blog-card-post" */).then(c => wrapFunctional(c.default || c))
export const BlogListPosts = () => import('../../components/pages/blog/BlogListPosts.vue' /* webpackChunkName: "components/blog-list-posts" */).then(c => wrapFunctional(c.default || c))
export const DiscountClubOfferPrice = () => import('../../components/pages/discount-club/DiscountClubOfferPrice.vue' /* webpackChunkName: "components/discount-club-offer-price" */).then(c => wrapFunctional(c.default || c))
export const CompaniesSupportContent = () => import('../../components/pages/empresas/CompaniesSupportContent.vue' /* webpackChunkName: "components/companies-support-content" */).then(c => wrapFunctional(c.default || c))
export const GoToStartButton = () => import('../../components/pages/home/GoToStartButton.vue' /* webpackChunkName: "components/go-to-start-button" */).then(c => wrapFunctional(c.default || c))
export const HomeMain = () => import('../../components/pages/home/HomeMain.vue' /* webpackChunkName: "components/home-main" */).then(c => wrapFunctional(c.default || c))
export const IconCheck = () => import('../../components/pages/home/IconCheck.vue' /* webpackChunkName: "components/icon-check" */).then(c => wrapFunctional(c.default || c))
export const AccreditedNetworkRegionFilter = () => import('../../components/pages/rede-credenciada/AccreditedNetworkRegionFilter.vue' /* webpackChunkName: "components/accredited-network-region-filter" */).then(c => wrapFunctional(c.default || c))
export const AccreditedNetworkSupportContent = () => import('../../components/pages/rede-credenciada/AccreditedNetworkSupportContent.vue' /* webpackChunkName: "components/accredited-network-support-content" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../components/pages/rede-credenciada/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const ClinicsList = () => import('../../components/pages/rede-credenciada/ClinicsList.vue' /* webpackChunkName: "components/clinics-list" */).then(c => wrapFunctional(c.default || c))
export const GoogleMap = () => import('../../components/pages/rede-credenciada/GoogleMap.vue' /* webpackChunkName: "components/google-map" */).then(c => wrapFunctional(c.default || c))
export const InternalLinks = () => import('../../components/pages/rede-credenciada/InternalLinks.vue' /* webpackChunkName: "components/internal-links" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../components/pages/rede-credenciada/Map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const ModalLocation = () => import('../../components/pages/rede-credenciada/ModalLocation.vue' /* webpackChunkName: "components/modal-location" */).then(c => wrapFunctional(c.default || c))
export const ModalSelectMyLocation = () => import('../../components/pages/rede-credenciada/ModalSelectMyLocation.vue' /* webpackChunkName: "components/modal-select-my-location" */).then(c => wrapFunctional(c.default || c))
export const MyLocation = () => import('../../components/pages/rede-credenciada/MyLocation.vue' /* webpackChunkName: "components/my-location" */).then(c => wrapFunctional(c.default || c))
export const SearchFilters = () => import('../../components/pages/rede-credenciada/SearchFilters.vue' /* webpackChunkName: "components/search-filters" */).then(c => wrapFunctional(c.default || c))
export const SeoCard = () => import('../../components/pages/rede-credenciada/SeoCard.vue' /* webpackChunkName: "components/seo-card" */).then(c => wrapFunctional(c.default || c))
export const ActionPage = () => import('../../components/shared/ActionPage/index.vue' /* webpackChunkName: "components/action-page" */).then(c => wrapFunctional(c.default || c))
export const DiscountClubTotalSaving = () => import('../../components/shared/DiscountClubTotalSaving/index.vue' /* webpackChunkName: "components/discount-club-total-saving" */).then(c => wrapFunctional(c.default || c))
export const Carousel = () => import('../../components/shared/Carousel/Carousel.vue' /* webpackChunkName: "components/carousel" */).then(c => wrapFunctional(c.default || c))
export const SectionAnchor = () => import('../../components/shared/SectionAnchor/index.vue' /* webpackChunkName: "components/section-anchor" */).then(c => wrapFunctional(c.default || c))
export const Payment = () => import('../../components/pages/Buy/Payment/index.vue' /* webpackChunkName: "components/payment" */).then(c => wrapFunctional(c.default || c))
export const PersonalData = () => import('../../components/pages/Buy/PersonalData/index.vue' /* webpackChunkName: "components/personal-data" */).then(c => wrapFunctional(c.default || c))
export const FormPetsPlans = () => import('../../components/pages/Buy/Plans/FormPetsPlans.vue' /* webpackChunkName: "components/form-pets-plans" */).then(c => wrapFunctional(c.default || c))
export const FormPetsResume = () => import('../../components/pages/Buy/Plans/FormPetsResume.vue' /* webpackChunkName: "components/form-pets-resume" */).then(c => wrapFunctional(c.default || c))
export const FormPetsResumeDropdown = () => import('../../components/pages/Buy/Plans/FormPetsResumeDropdown.vue' /* webpackChunkName: "components/form-pets-resume-dropdown" */).then(c => wrapFunctional(c.default || c))
export const FormPetsResumeList = () => import('../../components/pages/Buy/Plans/FormPetsResumeList.vue' /* webpackChunkName: "components/form-pets-resume-list" */).then(c => wrapFunctional(c.default || c))
export const Plans = () => import('../../components/pages/Buy/Plans/index.vue' /* webpackChunkName: "components/plans" */).then(c => wrapFunctional(c.default || c))
export const BenefitsSection = () => import('../../components/pages/benefits/sections/BenefitsSection.vue' /* webpackChunkName: "components/benefits-section" */).then(c => wrapFunctional(c.default || c))
export const BenefitsSectionBrand = () => import('../../components/pages/benefits/sections/BenefitsSectionBrand.vue' /* webpackChunkName: "components/benefits-section-brand" */).then(c => wrapFunctional(c.default || c))
export const BenefitsSectionPlan = () => import('../../components/pages/benefits/sections/BenefitsSectionPlan.vue' /* webpackChunkName: "components/benefits-section-plan" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionArguments = () => import('../../components/pages/home/sections/HomeSectionArguments.vue' /* webpackChunkName: "components/home-section-arguments" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionBenefits = () => import('../../components/pages/home/sections/HomeSectionBenefits.vue' /* webpackChunkName: "components/home-section-benefits" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionCTA = () => import('../../components/pages/home/sections/HomeSectionCTA.vue' /* webpackChunkName: "components/home-section-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionCoparticipation = () => import('../../components/pages/home/sections/HomeSectionCoparticipation.vue' /* webpackChunkName: "components/home-section-coparticipation" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionCoverage = () => import('../../components/pages/home/sections/HomeSectionCoverage.vue' /* webpackChunkName: "components/home-section-coverage" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionDiscountClub = () => import('../../components/pages/home/sections/HomeSectionDiscountClub.vue' /* webpackChunkName: "components/home-section-discount-club" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionFaq = () => import('../../components/pages/home/sections/HomeSectionFaq.vue' /* webpackChunkName: "components/home-section-faq" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionHowItWorks = () => import('../../components/pages/home/sections/HomeSectionHowItWorks.vue' /* webpackChunkName: "components/home-section-how-it-works" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionMicrochip = () => import('../../components/pages/home/sections/HomeSectionMicrochip.vue' /* webpackChunkName: "components/home-section-microchip" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionMustKnow = () => import('../../components/pages/home/sections/HomeSectionMustKnow.vue' /* webpackChunkName: "components/home-section-must-know" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionNowOrLater = () => import('../../components/pages/home/sections/HomeSectionNowOrLater.vue' /* webpackChunkName: "components/home-section-now-or-later" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionPartners = () => import('../../components/pages/home/sections/HomeSectionPartners.vue' /* webpackChunkName: "components/home-section-partners" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionPlans = () => import('../../components/pages/home/sections/HomeSectionPlans.vue' /* webpackChunkName: "components/home-section-plans" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionProduct = () => import('../../components/pages/home/sections/HomeSectionProduct.vue' /* webpackChunkName: "components/home-section-product" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionTag = () => import('../../components/pages/home/sections/HomeSectionTag.vue' /* webpackChunkName: "components/home-section-tag" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionTransparency = () => import('../../components/pages/home/sections/HomeSectionTransparency.vue' /* webpackChunkName: "components/home-section-transparency" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionTreatment = () => import('../../components/pages/home/sections/HomeSectionTreatment.vue' /* webpackChunkName: "components/home-section-treatment" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionVideoTestimonials = () => import('../../components/pages/home/sections/HomeSectionVideoTestimonials.vue' /* webpackChunkName: "components/home-section-video-testimonials" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionWarranty = () => import('../../components/pages/home/sections/HomeSectionWarranty.vue' /* webpackChunkName: "components/home-section-warranty" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionWhy = () => import('../../components/pages/home/sections/HomeSectionWhy.vue' /* webpackChunkName: "components/home-section-why" */).then(c => wrapFunctional(c.default || c))
export const Categories = () => import('../../components/pages/rede-credenciada/ClinicDetail/Categories.vue' /* webpackChunkName: "components/categories" */).then(c => wrapFunctional(c.default || c))
export const ClinicAddress = () => import('../../components/pages/rede-credenciada/ClinicDetail/ClinicAddress.vue' /* webpackChunkName: "components/clinic-address" */).then(c => wrapFunctional(c.default || c))
export const ClinicInfo = () => import('../../components/pages/rede-credenciada/ClinicDetail/ClinicInfo.vue' /* webpackChunkName: "components/clinic-info" */).then(c => wrapFunctional(c.default || c))
export const ClinicPhones = () => import('../../components/pages/rede-credenciada/ClinicDetail/ClinicPhones.vue' /* webpackChunkName: "components/clinic-phones" */).then(c => wrapFunctional(c.default || c))
export const ClinicSchema = () => import('../../components/pages/rede-credenciada/ClinicDetail/ClinicSchema.vue' /* webpackChunkName: "components/clinic-schema" */).then(c => wrapFunctional(c.default || c))
export const OpeningTimeTable = () => import('../../components/pages/rede-credenciada/ClinicDetail/OpeningTimeTable.vue' /* webpackChunkName: "components/opening-time-table" */).then(c => wrapFunctional(c.default || c))
export const Procedures = () => import('../../components/pages/rede-credenciada/ClinicDetail/Procedures.vue' /* webpackChunkName: "components/procedures" */).then(c => wrapFunctional(c.default || c))
export const Reviews = () => import('../../components/pages/rede-credenciada/ClinicDetail/Reviews.vue' /* webpackChunkName: "components/reviews" */).then(c => wrapFunctional(c.default || c))
export const SeveralClinics = () => import('../../components/pages/rede-credenciada/ClinicDetail/SeveralClinics.vue' /* webpackChunkName: "components/several-clinics" */).then(c => wrapFunctional(c.default || c))
export const Specialists = () => import('../../components/pages/rede-credenciada/ClinicDetail/Specialists.vue' /* webpackChunkName: "components/specialists" */).then(c => wrapFunctional(c.default || c))
export const ClinicDetail = () => import('../../components/pages/rede-credenciada/ClinicDetail/index.vue' /* webpackChunkName: "components/clinic-detail" */).then(c => wrapFunctional(c.default || c))
export const InputAutocompleteAddress = () => import('../../components/pages/rede-credenciada/ModalSelectAddress/InputAutocompleteAddress.vue' /* webpackChunkName: "components/input-autocomplete-address" */).then(c => wrapFunctional(c.default || c))
export const ModalSelectAddress = () => import('../../components/pages/rede-credenciada/ModalSelectAddress/index.vue' /* webpackChunkName: "components/modal-select-address" */).then(c => wrapFunctional(c.default || c))
export const Address = () => import('../../components/pages/Buy/Payment/CardAddress/Address.vue' /* webpackChunkName: "components/address" */).then(c => wrapFunctional(c.default || c))
export const CpfInput = () => import('../../components/pages/Buy/Payment/CardAddress/CpfInput.vue' /* webpackChunkName: "components/cpf-input" */).then(c => wrapFunctional(c.default || c))
export const IneligibleCPFAlertModal = () => import('../../components/pages/Buy/Payment/CardAddress/IneligibleCPFAlertModal.vue' /* webpackChunkName: "components/ineligible-c-p-f-alert-modal" */).then(c => wrapFunctional(c.default || c))
export const CardAddress = () => import('../../components/pages/Buy/Payment/CardAddress/index.vue' /* webpackChunkName: "components/card-address" */).then(c => wrapFunctional(c.default || c))
export const DiscountCoupon = () => import('../../components/pages/Buy/Payment/CardOrderSummary/DiscountCoupon.vue' /* webpackChunkName: "components/discount-coupon" */).then(c => wrapFunctional(c.default || c))
export const Discounts = () => import('../../components/pages/Buy/Payment/CardOrderSummary/Discounts.vue' /* webpackChunkName: "components/discounts" */).then(c => wrapFunctional(c.default || c))
export const InstallmentsValues = () => import('../../components/pages/Buy/Payment/CardOrderSummary/InstallmentsValues.vue' /* webpackChunkName: "components/installments-values" */).then(c => wrapFunctional(c.default || c))
export const PaymentDataPetsConfirmationResume = () => import('../../components/pages/Buy/Payment/CardOrderSummary/PaymentDataPetsConfirmationResume.vue' /* webpackChunkName: "components/payment-data-pets-confirmation-resume" */).then(c => wrapFunctional(c.default || c))
export const PaymentDataPetsResume = () => import('../../components/pages/Buy/Payment/CardOrderSummary/PaymentDataPetsResume.vue' /* webpackChunkName: "components/payment-data-pets-resume" */).then(c => wrapFunctional(c.default || c))
export const TotalPayment = () => import('../../components/pages/Buy/Payment/CardOrderSummary/TotalPayment.vue' /* webpackChunkName: "components/total-payment" */).then(c => wrapFunctional(c.default || c))
export const CardOrderSummary = () => import('../../components/pages/Buy/Payment/CardOrderSummary/index.vue' /* webpackChunkName: "components/card-order-summary" */).then(c => wrapFunctional(c.default || c))
export const BankDebitDetails = () => import('../../components/pages/Buy/Payment/CardPayment/BankDebitDetails.vue' /* webpackChunkName: "components/bank-debit-details" */).then(c => wrapFunctional(c.default || c))
export const BankSlipDetails = () => import('../../components/pages/Buy/Payment/CardPayment/BankSlipDetails.vue' /* webpackChunkName: "components/bank-slip-details" */).then(c => wrapFunctional(c.default || c))
export const CreditCardList = () => import('../../components/pages/Buy/Payment/CardPayment/CreditCardList.vue' /* webpackChunkName: "components/credit-card-list" */).then(c => wrapFunctional(c.default || c))
export const NewCreditCardDetails = () => import('../../components/pages/Buy/Payment/CardPayment/NewCreditCardDetails.vue' /* webpackChunkName: "components/new-credit-card-details" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodCardItem = () => import('../../components/pages/Buy/Payment/CardPayment/PaymentMethodCardItem.vue' /* webpackChunkName: "components/payment-method-card-item" */).then(c => wrapFunctional(c.default || c))
export const PixDetails = () => import('../../components/pages/Buy/Payment/CardPayment/PixDetails.vue' /* webpackChunkName: "components/pix-details" */).then(c => wrapFunctional(c.default || c))
export const CardPayment = () => import('../../components/pages/Buy/Payment/CardPayment/index.vue' /* webpackChunkName: "components/card-payment" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
